import styled from "styled-components"
import Img from "gatsby-image"

import { fonts } from "../../styles"

export const Container = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(180deg, #52d7a3 0%, rgba(5, 141, 185, 0.65) 100%);

  @media (max-width: 768px) {
    nav {
      display: none;
    }
  }
`

export const Wrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  height: 40px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  nav {
    a {
      text-decoration: none;
      cursor: pointer;
      text-transform: uppercase;
      font-size: ${fonts.small}px;
      color: ${props => props.theme.colors.white};
      transition: color 0.2s ease-in-out;

      &:hover {
        color: ${props => props.theme.opacity.dark};
      }
    }
    a + a {
      margin-left: 20px;
    }
  }

  > button {
    display: none;
    cursor: pointer;
    background: transparent;
    border: 0;
    outline: none;
    svg {
      color: red;
    }
  }

  @media (max-width: 768px) {
    height: 60px;
    padding: 1.6rem 1.6rem;

    button {
      display: inline-block;
    }
    h2 {
      display: none;
    }
  }
`

export const LeftContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-left: 10px;
    font-style: italic;
    font-size: ${fonts.big}px;
    text-transform: uppercase;
    color: ${props => props.theme.colors.white};
  }
`

export const StyledImage = styled(Img).attrs({
  resizeMode: "cover",
})``

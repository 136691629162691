import styled from "styled-components"

import { fonts } from "../../styles"

export const StyledBurger = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  background: linear-gradient(180deg, #52d7a3 0%, rgba(5, 141, 185, 0.65) 100%);
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  transition: all 0.3s linear;
  transform: ${({ isOpened }) =>
    isOpened ? "translateX(0)" : "translateX(-100%)"};

  &:focus {
    outline: none;
  }

  a {
    margin-top: 100px;
    text-decoration: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: ${fonts.bigger}px;
    color: ${props => props.theme.colors.white};
    transition: color 0.2s ease-in-out;

    &:hover {
      color: ${props => props.theme.opacity.dark};
    }
  }

  a + a {
    margin-top: 40px;
  }

  > button {
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 20px;
    background: transparent;
    border: 0;
    outline: none;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`

import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { MdClose } from "react-icons/md"

import * as S from "./styles"

function Burguer({ isOpened, setOpen }) {
  function handleClose() {
    setOpen(false)
  }

  return (
    <S.StyledBurger isOpened={isOpened}>
      <button onClick={handleClose}>
        <MdClose color="#fff" size={36} />
      </button>
      <AnchorLink href="#como-fazer" onClick={handleClose}>
        COMO FAZER?
      </AnchorLink>
      <AnchorLink href="#faq" onClick={handleClose}>
        Dúvidas Frequentes
      </AnchorLink>
      <AnchorLink href="#segura" onClick={handleClose}>
        Contato
      </AnchorLink>
    </S.StyledBurger>
  )
}

export default Burguer

import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"

import Header from "../Header"
import GLobalStyles from "../../styles/global"
import { defaultTheme } from "../../styles/theme"

import * as S from "./styles"

import "../../styles/fonts.css"

const Layout = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <GLobalStyles />
        <Header />
        <S.Container>{children}</S.Container>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { TiThMenu } from "react-icons/ti"

import Burguer from "../Burguer"

import * as S from "./styles"

function Header() {
  const [open, setOpen] = useState(false)

  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "granacred.png" }) {
        childImageSharp {
          fixed(width: 140) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  function handleToggle() {
    setOpen(prev => !prev)
  }

  return (
    <S.Container>
      <S.Wrapper>
        <S.LeftContent>
          <a
            href="https://granacred.com"
            arial-label="granacred website"
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.StyledImage
              fixed={logo.childImageSharp.fixed}
              alt="granacred logo"
            />
          </a>
          <h2>CRÉDITO PARA TODOS!</h2>
        </S.LeftContent>

        <nav>
          <AnchorLink href="#como-fazer">COMO FAZER?</AnchorLink>
          <AnchorLink href="#faq">Dúvidas Frequentes</AnchorLink>
          <AnchorLink href="#segura">Contato</AnchorLink>
        </nav>

        <button onClick={handleToggle}>
          <TiThMenu color="#fff" size={32} />
        </button>
        <Burguer setOpen={setOpen} isOpened={open} />
      </S.Wrapper>
    </S.Container>
  )
}

export default Header

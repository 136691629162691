import { shade, rgba, darken } from "polished"

export const defaultTheme = {
  colors: {
    white: "#fff",
    black: "#000",
  },
  text: {
    placeholder: shade(0.1, "#f2f2f2"),
    simpleText: "#595959",
    darkText: "#262626",
    orangeText: "#FF9B05",
    darkAquText: "#004b53",
    grayText: "#969696",
    errorText: "#F25656",
    lightOrangeText: "#FFD600",
    darkGrayText: "#5B5B5B",
  },
  backgrounds: {
    lightest: "#f2f2f2",
    lighter: "#fff",
    dark: "#191622",
    darker: "#15121e",
    darkest: "#131118",
    aqua: "#2dbecd",
    darkaqua: "#006E7A",
    orange: "#FF9B05",
    gray: "#c4c4c4",
    gradarkGray: "#5B5B5B",
  },
  opacity: {
    dark: rgba("#000", 0.3),
    light: rgba("#fff", 0.3),
    darkaqua: rgba("#006E7A", 0.9),
    shadeDarkAqua: shade(0.3, "#006E7A"),
    shadeOrange: shade(0.3, "#FF9B05"),
    hover: darken(0.05, "#FF9B05"),
  },
}

export default {
  superSmall: 12,
  smaller: 14,
  small: 16,
  regular: 18,
  big: 20,
  bigger: 24,
  superBig: 32,
  giant: 36,
  superGiant: 42,
}
